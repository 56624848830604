import React, { Fragment, useRef, useState } from 'react'
import Card from '../../../../../components/atoms/Card'
import Container from '../../../../../components/atoms/Container'
import Heading from '../../../../../components/atoms/Heading'
import Button from '../../../../../components/molecules/Button'
import { useTranslation } from '../../../../../providers/I18n'
import { BankCreditDetail } from '../../../../../types/credits'
import { StyledDescription } from '../../../../../components/styled/Description'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  addCreditDocument,
  addCreditHistory,
  getCredit,
  updateCredit,
} from '../../../../../services/credits'
import { getCreditColor } from '../../../../../utils/credits'
import { useUser } from '../../../../../providers/User'
import CreditWaitAction from '../../../shared/CreditWaitAction'
import Textarea from '../../../../../components/atoms/Textarea'
import Spinner from '../../../../../components/atoms/Spinner'
import { CreditPhases, CreditSteps, Role } from '../../../../../types/enums'
import FileDropper, {
  FileWithBuffer,
} from '../../../../../components/molecules/FileDropper'
import { uploadDocuments } from '../../../../../utils/documents'
// import { getNextOperator } from '../../../../../services/operators'
import { Mappeable } from '../../../../../types/objects'
import { ClientDocument } from '../../../../../types/clients'
import FileItem from '../../../../../components/molecules/FileItem'
import { useAlert } from '../../../../../providers/Alert'

const Phase1AppraisalDocuments: React.FC = () => {
  const commentRef = useRef<HTMLTextAreaElement>(null)

  const { code } = useParams()
  const { user } = useUser()
  const { setAlert } = useAlert()
  const { getTranslation } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [aguaFile, setAguaFile] = useState<FileWithBuffer[]>([])
  const [predialFile, setPredialFile] = useState<FileWithBuffer[]>([])
  const [escrituraFile, setEscrituraFile] = useState<FileWithBuffer[]>([])
  const [planoFile, setPlanoFile] = useState<FileWithBuffer[]>([])
  const [aperturaFile, setAperturaFile] = useState<FileWithBuffer[]>([])
  const [solicitudFile, setSolicitudFile] = useState<FileWithBuffer[]>([])
  const [otrosFile, setOtrosFile] = useState<FileWithBuffer[]>([])

  const {
    data: credit,
    isLoading,
    refetch,
  } = useQuery<BankCreditDetail>(['getCredit', code], () => getCredit(code!), {
    refetchOnWindowFocus: false,
  })

  let header
  let footer
  let content
  if (!credit && isLoading) {
    content = (
      <Container>
        <StyledDescription>Cargando credito...</StyledDescription>
      </Container>
    )
  } else if (credit) {
    const isActiveOwner = user!.code === credit.owners.advisor?.code
    const color = getCreditColor(credit.phase)
    const creditPhaseStep = `${getTranslation!(
      `phases.${credit.phase}.title`
    )} - ${getTranslation!(`phases.${credit.phase}.steps.${credit.step}`)}`

    const uploadedCreditDocuments = credit.documents.reduce<
      Mappeable<ClientDocument>
    >((map, document) => {
      map[document.name] = document
      return map
    }, {})

    header = (
      <Container padding>
        <Heading type="3" color={color}>
          {creditPhaseStep}
        </Heading>
      </Container>
    )

    if (isActiveOwner || user?.role !== Role.Advisor) {
      const aguaBuffer = aguaFile
        ? aguaFile.map((fileWithBuffer) => {
            fileWithBuffer.fixedName = 'agua'
            return fileWithBuffer.file
          })
        : []

      const predialBuffer = predialFile
        ? predialFile.map((fileWithBuffer) => {
            fileWithBuffer.fixedName = 'predial'
            return fileWithBuffer.file
          })
        : []

      const escrituraBuffer = escrituraFile
        ? escrituraFile.map((fileWithBuffer) => {
            fileWithBuffer.fixedName = 'escritura'
            return fileWithBuffer.file
          })
        : []

      const planoBuffer = planoFile
        ? planoFile.map((fileWithBuffer) => {
            fileWithBuffer.fixedName = 'plano'
            return fileWithBuffer.file
          })
        : []

      const aperturaBuffer = aperturaFile
        ? aperturaFile.map((fileWithBuffer) => {
            fileWithBuffer.fixedName = 'apertura'
            return fileWithBuffer.file
          })
        : []

      const solicitudBuffer = solicitudFile
        ? solicitudFile.map((fileWithBuffer) => {
            fileWithBuffer.fixedName = 'solicitudAppraisal'
            return fileWithBuffer.file
          })
        : []

      const otrosBuffer = otrosFile
        ? otrosFile.map((fileWithBuffer) => {
            fileWithBuffer.fixedName = 'othersAppraisal'
            return fileWithBuffer.file
          })
        : []

      const handleSendToAppraisal = async () => {
        setLoading(true)

        // const operator = await getNextOperator(CreditPhases.Phase2)
        const operator = credit.owners.phase1Operator._id

        const hasComment = !!commentRef.current?.value
        if (!hasComment) {
          setAlert!({
            severity: 'error',
            title: 'Error',
            description: 'Hacen falta campos requeridos',
          })
          setLoading(false)
        } else {
          await updateCredit(credit!.code, {
            phase: CreditPhases.Phase2,
            step: CreditSteps.Phase2AppraisalRequest,
            owners: {
              phase2Operator: operator,
            },
          }).then(async () => {
            const documents: FileWithBuffer[] = []
            if (aguaBuffer.length > 0) {
              documents.push(...aguaFile)
            }
            if (predialBuffer.length > 0) {
              documents.push(...predialFile)
            }
            if (escrituraBuffer.length > 0) {
              documents.push(...escrituraFile)
            }
            if (planoBuffer.length > 0) {
              documents.push(...planoFile)
            }
            if (aperturaBuffer.length > 0) {
              documents.push(...aperturaFile)
            }
            if (solicitudBuffer.length > 0) {
              documents.push(...solicitudFile)
            }
            if (otrosBuffer.length > 0) {
              documents.push(...otrosFile)
            }

            const pushedCreditDocuments = await uploadDocuments(
              documents,
              `credit/${credit!.code.toString()}`
            )

            pushedCreditDocuments.forEach((document) => {
              addCreditDocument(credit.code, document!)
            })
            const comment = `Avanzando a Etapa 2 - Revision para Avalúo :: ${commentRef.current?.value}`
            const creditHistoryComment = {
              documents: pushedCreditDocuments,
              comment: comment,
              type: 'status',
              start: new Date(),
              change: {
                from: {
                  owner: credit.owners.advisor._id,
                  phase: credit.phase,
                  step: credit.step,
                },
                to: {
                  owner: operator,
                  phase: CreditPhases.Phase2,
                  step: CreditSteps.Phase2AppraisalRequest,
                },
              },
            }

            await addCreditHistory(credit!.code, creditHistoryComment).then(
              () => {
                if (commentRef.current) {
                  commentRef.current.value = ''
                }
                refetch().then(() => {
                  setLoading(false)
                })
              }
            )
          })
        }
      }

      const handleSendBack = async () => {
        setLoading(true)

        await updateCredit(credit!.code, {
          step: CreditSteps.Phase1Authorized,
        }).then(async () => {
          const comment = `Regresando a Etapa 1 - Autorizado :: ${commentRef.current?.value}`
          const creditHistoryComment = {
            comment: comment,
            type: 'status',
            start: new Date(),
            change: {
              from: {
                step: credit.step,
              },
              to: {
                step: CreditSteps.Phase1Authorized,
              },
            },
          }

          await addCreditHistory(credit!.code, creditHistoryComment).then(
            () => {
              if (commentRef.current) {
                commentRef.current.value = ''
              }
              refetch().then(() => {
                setLoading(false)
              })
            }
          )
        })
      }

      content = (
        <Fragment>
          <StyledDescription>
            Subir documentos completos, legibles, para solicitar avalúo,
            asegurate que sean los correctos.
          </StyledDescription>
          {!uploadedCreditDocuments['agua'] ? (
            <FileDropper
              label="Agua"
              onChange={setAguaFile}
              files={aguaBuffer}
            />
          ) : (
            <FileItem
              id="agua"
              url={uploadedCreditDocuments['agua'].url}
              uploadedAt={uploadedCreditDocuments['agua'].uploadedAt}
              onChange={setAguaFile}
            />
          )}

          {!uploadedCreditDocuments['predial'] ? (
            <FileDropper
              label="Predial"
              onChange={setPredialFile}
              files={predialBuffer}
            />
          ) : (
            <FileItem
              id="predial"
              url={uploadedCreditDocuments['predial'].url}
              uploadedAt={uploadedCreditDocuments['predial'].uploadedAt}
              onChange={setPredialFile}
            />
          )}

          {!uploadedCreditDocuments['escritura'] ? (
            <FileDropper
              label="Escritura"
              onChange={setEscrituraFile}
              files={escrituraBuffer}
            />
          ) : (
            <FileItem
              id="escritura"
              url={uploadedCreditDocuments['escritura'].url}
              uploadedAt={uploadedCreditDocuments['escritura'].uploadedAt}
              onChange={setEscrituraFile}
            />
          )}

          {!uploadedCreditDocuments['plano'] ? (
            <FileDropper
              label="Plano / Petición Levantamiento"
              onChange={setPlanoFile}
              files={planoBuffer}
            />
          ) : (
            <FileItem
              id="plano"
              url={uploadedCreditDocuments['plano'].url}
              uploadedAt={uploadedCreditDocuments['plano'].uploadedAt}
              onChange={setPlanoFile}
            />
          )}

          {!uploadedCreditDocuments['apertura'] ? (
            <FileDropper
              label="Apertura Cuenta Cliente"
              onChange={setAperturaFile}
              files={aperturaBuffer}
            />
          ) : (
            <FileItem
              id="apertura"
              url={uploadedCreditDocuments['apertura'].url}
              uploadedAt={uploadedCreditDocuments['apertura'].uploadedAt}
              onChange={setAperturaFile}
            />
          )}

          {!uploadedCreditDocuments['solicitudAppraisal'] ? (
            <FileDropper
              label="Solicitud Avalúo / Avalúo Externo"
              onChange={setSolicitudFile}
              files={solicitudBuffer}
            />
          ) : (
            <FileItem
              id="solicitudAppraisal"
              url={uploadedCreditDocuments['solicitudAppraisal'].url}
              uploadedAt={
                uploadedCreditDocuments['solicitudAppraisal'].uploadedAt
              }
              onChange={setSolicitudFile}
            />
          )}

          {!uploadedCreditDocuments['othersAppraisal'] ? (
            <FileDropper
              label="Otros"
              onChange={setOtrosFile}
              files={otrosBuffer}
            />
          ) : (
            <FileItem
              id="othersAppraisal"
              url={uploadedCreditDocuments['othersAppraisal'].url}
              uploadedAt={uploadedCreditDocuments['othersAppraisal'].uploadedAt}
              onChange={setOtrosFile}
            />
          )}

          <Textarea label="Comentarios" ref={commentRef} required />
        </Fragment>
      )

      footer = (
        <Container align="right" direction="row" padding>
          {loading ? (
            <Spinner type="bar" />
          ) : (
            <Fragment>
              {user?.role !== Role.Advisor && (
                <Button
                  color={color}
                  priority="secondary"
                  onClick={handleSendBack}
                >
                  Regresar a Autorización
                </Button>
              )}
              <Button color={color} onClick={handleSendToAppraisal}>
                Mandar a Revision Avalúo
              </Button>
            </Fragment>
          )}
        </Container>
      )
    } else {
      content = <CreditWaitAction owner={credit.owners.phase1Operator} />
    }
  }

  return (
    <div>
      <Card header={header} footer={footer}>
        {content}
      </Card>
    </div>
  )
}

export default Phase1AppraisalDocuments
