import React, { Fragment, useMemo, useRef, useState } from 'react'
import Card from '../../../../../components/atoms/Card'
import Container from '../../../../../components/atoms/Container'
import Heading from '../../../../../components/atoms/Heading'
import Button from '../../../../../components/molecules/Button'
import { useTranslation } from '../../../../../providers/I18n'
import { BankCreditDetail } from '../../../../../types/credits'
import { StyledDescription } from '../../../../../components/styled/Description'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  addCreditDocument,
  addCreditHistory,
  getCredit,
  updateCredit,
} from '../../../../../services/credits'
import { getCreditColor } from '../../../../../utils/credits'
import { useUser } from '../../../../../providers/User'
import CreditWaitAction from '../../../shared/CreditWaitAction'
import Textarea from '../../../../../components/atoms/Textarea'
import { CreditPhases, CreditSteps, Role } from '../../../../../types/enums'
import Spinner from '../../../../../components/atoms/Spinner'
import FileDropper, {
  FileWithBuffer,
} from '../../../../../components/molecules/FileDropper'
import { uploadDocuments } from '../../../../../utils/documents'
import Input from '../../../../../components/atoms/Input'
import NumericInput from '../../../../../components/atoms/NumericInput'
import Select from '../../../../../components/atoms/Select'
import { SelectOption } from '../../../../../types/html'
import { useCatalogs } from '../../../../../providers/Catalogs'
import { formatCurrency } from '../../../../../utils/currency'
import { useAlert } from '../../../../../providers/Alert'

const Phase3Formalization: React.FC = () => {
  const valorOperacionRef = useRef<HTMLInputElement>(null)
  const montoCreditoFormalizacionRef = useRef<HTMLInputElement>(null)
  const dispersionRecursosRef = useRef<HTMLTextAreaElement>(null)
  const productoFormalizacionRef = useRef<HTMLInputElement>(null)
  const creditoFormalizacionRef = useRef<HTMLInputElement>(null)
  const notariaFormalizacionRef = useRef<HTMLInputElement>(null)
  const abogadoRef = useRef<HTMLInputElement>(null)
  const abogadoEmailRef = useRef<HTMLInputElement>(null)
  const subproductoFormalizacionRef = useRef<HTMLInputElement>(null)
  const termsFormalizacionRef = useRef<HTMLInputElement>(null)
  const tasaFormalizacionRef = useRef<HTMLInputElement>(null)
  const retencionGastosNotarialesRef = useRef<HTMLInputElement>(null)
  const participaCoacreditadoRef = useRef<HTMLInputElement>(null)
  const participaObligadoMatrimonialRef = useRef<HTMLInputElement>(null)
  const commentRef = useRef<HTMLTextAreaElement>(null)

  const { code } = useParams()
  const { user } = useUser()
  const { setAlert } = useAlert()
  const { banks, notarias } = useCatalogs()
  const [loading, setLoading] = useState<boolean>(false)
  const { getTranslation } = useTranslation()
  const [selectedCreditType, setSelectedCreditType] =
    useState<SelectOption | null>(null)
  const [selectedProductType, setSelectedProductType] =
    useState<SelectOption | null>(null)
  const [estadoCuentaFormalizacionFile, setEstadoCuentaFile] = useState<
    FileWithBuffer[]
  >([])
  const [aperturaClienteFormalizacion, setAperturaClienteFormalizacionFile] =
    useState<FileWithBuffer[]>([])
  const [otrosFormalizacion, setOtrosFormalizacion] = useState<
    FileWithBuffer[]
  >([])
  const {
    data: credit,
    isLoading,
    refetch,
  } = useQuery<BankCreditDetail>(['getCredit', code], () => getCredit(code!), {
    refetchOnWindowFocus: false,
  })

  const handleCreditTypeChange = (item: SelectOption) => {
    setSelectedCreditType(item)
    setSelectedProductType(null)
  }

  const handleProductTypeChange = (item: SelectOption) => {
    setSelectedProductType(item)
  }

  const notariasToItems = useMemo(() => {
    return notarias?.map((notaria) => {
      return {
        value: notaria._id,
        label: notaria.name,
      }
    })
  }, [notarias])

  const creditTypesPerBank = useMemo(() => {
    if (banks && credit?.bank) {
      const bankInfo = banks.find((bank) => bank._id === credit?.bank._id)
      return bankInfo?.creditTypes.map((creditType) => {
        return {
          value: creditType.type._id,
          label: creditType.type.name,
        }
      })
    }
    return []
  }, [banks, credit?.bank])

  const productTypesPerCreditType = useMemo(() => {
    if (banks && credit?.bank && selectedCreditType) {
      const bankInfo = banks.find((bank) => bank._id === credit?.bank._id)
      const creditTypeInfo = bankInfo?.creditTypes.find(
        (creditType) => creditType.type._id === selectedCreditType.value
      )

      if (creditTypeInfo) {
        return creditTypeInfo.products.map((productType) => {
          return {
            value: productType._id,
            label: productType.name,
          }
        })
      }
      return []
    }
    return []
  }, [banks, selectedCreditType, credit?.bank])

  const yesNoItems = [
    {
      value: 'Si',
      label: 'Si',
    },
    {
      value: 'No',
      label: 'No',
    },
  ]

  let header
  let footer
  let content
  if (!credit && isLoading) {
    content = (
      <Container>
        <StyledDescription>Cargando credito...</StyledDescription>
      </Container>
    )
  } else if (credit) {
    const isActiveOwner = user!.code === credit.owners.advisor?.code
    const color = getCreditColor(credit.phase)
    const creditPhaseStep = `${getTranslation!(
      `phases.${credit.phase}.title`
    )} - ${getTranslation!(`phases.${credit.phase}.steps.${credit.step}`)}`

    header = (
      <Container padding>
        <Heading type="3" color={color}>
          {creditPhaseStep}
        </Heading>
      </Container>
    )

    if (isActiveOwner || user?.role !== Role.Advisor) {
      const estadoCuentaBuffer = estadoCuentaFormalizacionFile
        ? estadoCuentaFormalizacionFile.map((fileWithBuffer) => {
            fileWithBuffer.fixedName = 'estadoCuentaFormalizacion'
            return fileWithBuffer.file
          })
        : []

      const aperturaFormalizacionBuffer = aperturaClienteFormalizacion
        ? aperturaClienteFormalizacion.map((fileWithBuffer) => {
            fileWithBuffer.fixedName = 'aperturaFormalizacion'
            return fileWithBuffer.file
          })
        : []

      const otrosFormalizacionBuffer = otrosFormalizacion
        ? otrosFormalizacion.map((fileWithBuffer) => {
            fileWithBuffer.fixedName = 'otrosFormalizacion'
            return fileWithBuffer.file
          })
        : []

      const handleSendToFormalizacion = async () => {
        setLoading(true)

        const retencionGastosOption = JSON.parse(
          retencionGastosNotarialesRef.current?.value || '{}'
        )

        const participaCoacreditadoOption = JSON.parse(
          participaCoacreditadoRef.current?.value || '{}'
        )

        const participaObligadoMatrimonialOption = JSON.parse(
          participaObligadoMatrimonialRef.current?.value || '{}'
        )

        const creditTypeOption = JSON.parse(
          creditoFormalizacionRef.current?.value || '{}'
        )

        const productTypeOption = JSON.parse(
          productoFormalizacionRef.current?.value || '{}'
        )

        const notariaOption = JSON.parse(
          notariaFormalizacionRef.current?.value || '{}'
        )

        const formalizacionInfo = {
          formalizacionValorOperacion: parseFloat(
            valorOperacionRef.current?.value || ''
          ),
          formalizacionMontoCredito: parseFloat(
            montoCreditoFormalizacionRef.current?.value || ''
          ),
          formalizacionDispersionRecursos:
            dispersionRecursosRef.current?.value || '',
          formalizacionCredito: creditTypeOption.value || '',
          formalizacionProducto: productTypeOption.value || '',
          formalizacionSubProducto:
            subproductoFormalizacionRef.current?.value || '',
          formalizacionNotaria: notariaOption.value || '',
          formalizacionAbogado: abogadoRef.current?.value || '',
          formalizacionAbogadoEmail: abogadoEmailRef.current?.value || '',
          formalizacionTerms: termsFormalizacionRef.current?.value || '',
          formalizacionTasa: tasaFormalizacionRef.current?.value || '',
          formalizacionRetencionGastos: retencionGastosOption.value || '',
          formalizacionParticipaCoacreditado:
            participaCoacreditadoOption.value || '',
          formalizacionParticipaObligadoMatrimonial:
            participaObligadoMatrimonialOption.value || '',
        }

        const hasComment = !!commentRef.current?.value
        const hasValuacion = !!valorOperacionRef.current?.value
        const hasCreditAmount = !!montoCreditoFormalizacionRef.current?.value
        const hasDispersion = !!dispersionRecursosRef.current?.value
        const hasCreditType = !!creditTypeOption.value
        const hasProductType = !!productTypeOption.value
        const hasTerms = !!termsFormalizacionRef.current?.value
        const hasTasa = !!tasaFormalizacionRef.current?.value
        const hasNotaria = !!notariaOption.value
        const hasAbogadoName = !!abogadoRef.current?.value
        const hasAbogadoEmail = !!abogadoEmailRef.current?.value
        const hasRetencion = !!retencionGastosOption.value
        const hasParticipaCo = !!participaCoacreditadoOption.value
        if (
          !hasComment ||
          !hasValuacion ||
          !hasCreditAmount ||
          !hasDispersion ||
          !hasCreditType ||
          !hasProductType ||
          !hasTerms ||
          !hasTasa ||
          !hasNotaria ||
          !hasAbogadoName ||
          !hasAbogadoEmail ||
          !hasRetencion ||
          !hasParticipaCo
        ) {
          setAlert!({
            severity: 'error',
            title: 'Error',
            description: 'Hacen falta campos requeridos',
          })
          setLoading(false)
        } else {
          await updateCredit(credit!.code, {
            phase: CreditPhases.Phase3,
            step: CreditSteps.Phase3ClosingValues,
            meta: formalizacionInfo,
          }).then(async () => {
            const documents: FileWithBuffer[] = []
            if (estadoCuentaFormalizacionFile.length > 0) {
              documents.push(...estadoCuentaFormalizacionFile)
            }

            if (aperturaFormalizacionBuffer.length > 0) {
              documents.push(...aperturaClienteFormalizacion)
            }

            if (otrosFormalizacionBuffer.length > 0) {
              documents.push(...otrosFormalizacion)
            }

            const pushedCreditDocuments = await uploadDocuments(
              documents,
              `credit/${credit!.code.toString()}`
            )

            pushedCreditDocuments.forEach((document) => {
              addCreditDocument(credit.code, document!)
            })

            const comment = `Avanzando a Etapa 3 - Cierre de Cifras :: ${commentRef.current?.value}`
            const creditHistoryComment = {
              comment: comment,
              meta: {
                ...formalizacionInfo,
                formalizacionCredito: creditTypeOption.label || '',
                formalizacionProducto: productTypeOption.label || '',
                formalizacionSubProducto:
                  subproductoFormalizacionRef.current?.value || '',
                formalizacionNotaria: notariaOption.label || '',
                formalizacionValorOperacion: formatCurrency(
                  formalizacionInfo.formalizacionValorOperacion
                ),
                formalizacionMontoCredito: formatCurrency(
                  formalizacionInfo.formalizacionMontoCredito
                ),
              },
              type: 'status',
              start: new Date(),
              change: {
                from: {
                  owner: credit.owners.advisor._id,
                  phase: credit.phase,
                  step: credit.step,
                },
                to: {
                  owner: credit.owners.phase3Operator._id,
                  phase: CreditPhases.Phase3,
                  step: CreditSteps.Phase3ClosingValues,
                },
              },
            }
            await addCreditHistory(credit.code, creditHistoryComment).then(
              () => {
                refetch().then(() => {
                  setLoading(false)
                })
              }
            )
          })
        }
      }

      const handleSendBack = async () => {
        setLoading(true)

        await updateCredit(credit!.code, {
          phase: CreditPhases.Phase2,
          step: CreditSteps.Phase2AppraisalClosed,
        }).then(async () => {
          const comment = `Regresando a Etapa 2 - Avalúo Cerrado :: ${commentRef.current?.value}`
          const creditHistoryComment = {
            comment: comment,
            type: 'status',
            start: new Date(),
            change: {
              from: {
                owner: credit.owners.advisor._id,
                phase: credit.phase,
                step: credit.step,
              },
              to: {
                owner: credit.owners.phase2Operator._id,
                phase: CreditPhases.Phase2,
                step: CreditSteps.Phase2AppraisalClosed,
              },
            },
          }

          await addCreditHistory(credit!.code, creditHistoryComment).then(
            () => {
              if (commentRef.current) {
                commentRef.current.value = ''
              }
              refetch().then(() => {
                setLoading(false)
              })
            }
          )
        })
      }

      content = (
        <Fragment>
          <StyledDescription>
            Confirma y captura condiciones financieras para que te generen
            instrucción Notarial.
          </StyledDescription>
          <NumericInput
            label="Valor de Operacion"
            required
            ref={valorOperacionRef}
          />
          <NumericInput
            label="Monto de Credito"
            required
            ref={montoCreditoFormalizacionRef}
          />
          <Textarea
            label="Dispersion de Recursos"
            required
            ref={dispersionRecursosRef}
          />
          <Select
            label="Tipo de Credito"
            required
            onChange={handleCreditTypeChange}
            value={selectedCreditType}
            items={creditTypesPerBank}
            ref={creditoFormalizacionRef}
          />
          <Select
            label="Tipo de Producto"
            required
            onChange={handleProductTypeChange}
            value={selectedProductType}
            items={productTypesPerCreditType}
            ref={productoFormalizacionRef}
          />
          <Input
            label="Subtipo de Producto (en caso de aplicar)"
            ref={subproductoFormalizacionRef}
            type="text"
          />
          <Input
            label="Plazo en Años"
            required
            ref={termsFormalizacionRef}
            type="number"
            max="20"
          />
          <Input label="Tasa %" required ref={tasaFormalizacionRef} />
          <Select
            label="Notaria"
            required
            items={notariasToItems}
            ref={notariaFormalizacionRef}
          />
          <Input label="Abogado Nombre" required ref={abogadoRef} />
          <Input
            label="Abogado Correo"
            required
            ref={abogadoEmailRef}
            type="email"
          />
          <Select
            label="Retencion de Gastos Notariales"
            required
            items={yesNoItems}
            ref={retencionGastosNotarialesRef}
          />
          <Select
            label="Participa Coacreditado Ingresos"
            required
            items={yesNoItems}
            ref={participaCoacreditadoRef}
          />
          {credit.client.meta.maritalStatus === 'Casado' && (
            <Select
              label="Participa Obligado Regimen Matrimonial"
              required
              items={yesNoItems}
              ref={participaObligadoMatrimonialRef}
            />
          )}
          <FileDropper
            label="Estado de Cuenta Vendedor"
            onChange={setEstadoCuentaFile}
            files={estadoCuentaBuffer}
          />
          <FileDropper
            label="Apertura Cuenta Cliente"
            onChange={setAperturaClienteFormalizacionFile}
            files={aperturaFormalizacionBuffer}
          />
          <FileDropper
            label="Otros"
            onChange={setOtrosFormalizacion}
            files={otrosFormalizacionBuffer}
          />
          <Textarea label="Comentarios" ref={commentRef} required />
        </Fragment>
      )

      footer = (
        <Container align="right" direction="row" padding>
          {loading ? (
            <Spinner type="bar" />
          ) : (
            <>
              {user?.role !== Role.Advisor && (
                <Button
                  color={color}
                  priority="secondary"
                  onClick={handleSendBack}
                  loading={loading}
                >
                  Regresar a Avalúo Cerrado
                </Button>
              )}
              <Button onClick={handleSendToFormalizacion} color={color}>
                Mandar Para Formalización
              </Button>
            </>
          )}
        </Container>
      )
    } else {
      content = <CreditWaitAction owner={credit.owners.phase3Operator} />
    }
  }

  return (
    <div>
      <Card header={header} footer={footer}>
        {content}
      </Card>
    </div>
  )
}

export default Phase3Formalization
