import {
  BankCredit,
  BankCreditDetail,
  BankCreditListItem,
  BankCreditPayload,
} from '../types/credits'
import { CreditPhases, CreditSteps, Owners, Role } from '../types/enums'
import { FollowUpRow } from '../types/follow-up'
import { User } from '../types/user'
import { STEPS_OWNERS } from './steps'

const phaseColors = {
  [CreditPhases.Prequalification]: 'var(--color-pink-2)',
  [CreditPhases.Phase1]: 'var(--color-yellow-2)',
  [CreditPhases.Phase2]: 'var(--color-blue-2)',
  [CreditPhases.Phase3]: 'var(--color-green-2)',
}

export function transformBankCreditInput(
  clientId: string,
  bankCredit: BankCredit,
  assignedOperator: string
): BankCreditPayload {
  const phase = bankCredit.phase.value
  let step
  if (phase === CreditPhases.Phase1) {
    step = CreditSteps.Phase1Assignation
  } else if (phase === CreditPhases.Prequalification) {
    step = CreditSteps.PrequalificationRequest
  }

  return {
    client: clientId,
    bank: bankCredit.bank.value,
    phase: phase as CreditPhases,
    step: step as CreditSteps,
    creditType: bankCredit.creditType,
    productType: bankCredit.productType,
    comments: bankCredit.comments,
    terms: bankCredit.term,
    houseValue: bankCredit.houseValue,
    creditRequested: bankCredit.creditRequested,
    expectedSignatureDate: bankCredit.expectedSignatureDate,
    assignedOperator,
  }
}

export function parseBankCreditForTable(
  credits: BankCreditListItem[],
  languages: {
    [key: string]: string
  }
): FollowUpRow[] {
  const bankCredits = credits.map((credit) => {
    const operator = Object.keys(credit.owners).find((owner) => {
      const currentOwner = PhaseOwner[credit.phase]

      if (currentOwner === owner) {
        return true
      }
      return false
    })
    const currentOwner = credit.owners[operator as Owners]

    const pesosFormatter = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    })

    const phaseTranslation = languages[`phases.${credit.phase}.title`]
    const phaseStepTranslation =
      languages[`phases.${credit.phase}.steps.${credit.step}`]

    const currentOperatorKey = PhaseOwner[credit.phase]
    const currentOperator = credit.owners[currentOperatorKey]

    let creditAmount
    if (credit.meta.finalCreditAmount) {
      creditAmount = parseFloat(credit.meta.finalCreditAmount)
    } else if (credit.meta.formalizacionMontoCredito) {
      creditAmount = credit.meta.formalizacionMontoCredito
    } else if (credit.meta.authorizedCredit) {
      creditAmount = credit.meta.authorizedCredit
    } else {
      creditAmount = credit.meta.creditRequested
    }

    const roleOwner = STEPS_OWNERS[credit.step]
    let owner = credit.owners[credit.currentOwner as Owners]
    if (!owner) {
      owner = credit.owners[roleOwner]
    }

    const expirationDate =
      credit.meta.authorizedExpDate &&
      new Date(`${credit.meta.authorizedExpDate} 12:00:00`)

    return {
      id: `${credit.code}`,
      visualId: `CR-${credit.code}`,
      advisor: {
        active: owner._id === credit.owners.advisor._id,
        text: credit.owners.advisor.displayName,
      },
      operator: {
        active: owner._id === currentOwner._id,
        text: currentOwner.displayName,
      },
      createdAt: new Date(credit.createdAt || '').toLocaleDateString('es-MX'),
      client: credit.client.name.toUpperCase(),
      bank: credit.bank.name,
      expirationDate:
        expirationDate && new Date(expirationDate).toLocaleDateString('es-MX'),
      productType: credit.meta.productType.name,
      contactForm: credit.client.meta.contactForm.name,
      creditAmount: pesosFormatter.format(creditAmount),
      step: credit.step,
      currentOperator: currentOperator.code,
      visualStep: {
        text: `${phaseTranslation} / ${phaseStepTranslation}`,
        color: getCreditColor(credit.phase),
      },
    }
  })
  return bankCredits
}

export function getConditionalStyles(user: User) {
  const assignationSteps = [
    CreditSteps.PrequalificationRequest,
    CreditSteps.Phase1Assignation,
    CreditSteps.Phase2AppraisalRequest,
    CreditSteps.Phase3ClosingValues,
  ]

  const showInRed = (row: FollowUpRow) => {
    return (
      assignationSteps.includes(row.step as CreditSteps) &&
      (user.code === row.currentOperator || user.role !== Role.Operator)
    )
  }

  const showInGray = (row: FollowUpRow) => {
    return user.code !== row.currentOperator && user.role === Role.Operator
  }

  const asignationRule = (row: FollowUpRow) =>
    user.role !== Role.Advisor
      ? {
          backgroundColor: showInRed(row) ? 'var(--color-red-5)' : 'inherit',
        }
      : {}

  const notAssignedRule = (row: FollowUpRow) =>
    user.role === Role.Operator
      ? {
          backgroundColor: showInGray(row) ? 'var(--color-gray-5)' : 'inherit',
        }
      : {}
  return [
    {
      when: (row: FollowUpRow) => showInRed(row),
      style: (row: FollowUpRow) => ({
        ...asignationRule(row),
      }),
    },
    {
      when: (row: FollowUpRow) => showInGray(row),
      style: (row: FollowUpRow) => ({
        ...notAssignedRule(row),
      }),
    },
  ]
}

export function getCreditColor(phase: CreditPhases) {
  const color = phaseColors[phase]
  return color
}

export function isCreditOwner(credit: BankCreditDetail, user: User) {
  return credit.owners.advisor.code === user.code
}

export const PhaseOwner = {
  [CreditPhases.Prequalification]: Owners.PrequalificationOperator,
  [CreditPhases.Phase1]: Owners.Phase1Operator,
  [CreditPhases.Phase2]: Owners.Phase2Operator,
  [CreditPhases.Phase3]: Owners.Phase3Operator,
}
