import { ConfigRoles } from '../types/config'
import { Pages, Role } from '../types/enums'
import { BasicAllocationColumns } from './allocation'
import { BasicCelulasPerformance } from './celulasPerformance'
import { BasicClientColumns } from './clients'
import { BasicCommissionsColumns, AdminCommissionsColumns } from './commissions'
import { BasicFollowUpColumns } from './follow-up'
import { BasicSavingFundsColumns } from './savingFunds'

const RolesConfig: ConfigRoles = {
  [Role.Advisor]: {
    pages: [
      Pages.Home,
      Pages.NewClient,
      Pages.Clients,
      Pages.NewCredit,
      Pages.FollowUp,
      Pages.Allocation,
      Pages.Commissions,
      Pages.PaidCommissions,
      Pages.SavingsFunds,
      Pages.Trash,
      Pages.AuthorizationCard,
      Pages.ClientDetail,
      Pages.CreditDetail,
    ],
    commissions: BasicCommissionsColumns,
    allocation: BasicAllocationColumns,
    followUp: BasicFollowUpColumns,
    clients: BasicClientColumns,
    celulasPerformance: BasicCelulasPerformance,
    savingFunds: BasicSavingFundsColumns,
  },
  [Role.Operator]: {
    pages: [
      Pages.Home,
      Pages.Clients,
      Pages.NewCredit,
      Pages.FollowUp,
      Pages.Allocation,
      Pages.Commissions,
      Pages.PaidCommissions,
      Pages.AuthorizationCard,
      Pages.Trash,
      Pages.ClientDetail,
      Pages.CreditDetail,
    ],
    commissions: BasicCommissionsColumns,
    allocation: BasicAllocationColumns,
    followUp: BasicFollowUpColumns,
    clients: BasicClientColumns,
    celulasPerformance: BasicCelulasPerformance,
    savingFunds: BasicSavingFundsColumns,
  },
  [Role.Manager]: {
    pages: [
      Pages.Home,
      Pages.NewClient,
      Pages.Clients,
      Pages.NewCredit,
      Pages.FollowUpMore,
      Pages.Operators,
      Pages.Allocation,
      Pages.AuthorizationCard,
      Pages.Trash,
      Pages.ClientDetail,
      Pages.CreditDetail,
    ],
    commissions: AdminCommissionsColumns,
    allocation: BasicAllocationColumns,
    followUp: BasicFollowUpColumns,
    clients: BasicClientColumns,
    celulasPerformance: BasicCelulasPerformance,
    savingFunds: BasicSavingFundsColumns,
  },
  [Role.Director]: {
    pages: [
      Pages.Home,
      Pages.NewClient,
      Pages.Clients,
      Pages.NewCredit,
      Pages.FollowUpMore,
      Pages.Allocation,
      Pages.Commissions,
      Pages.PaidCommissions,
      Pages.SavingsFunds,
      Pages.Trash,
      Pages.AuthorizationCard,
      Pages.ClientDetail,
      Pages.CreditDetail,
      Pages.CommissionsControl,
      Pages.Celulas,
      Pages.Operators,
    ],
    commissions: AdminCommissionsColumns,
    allocation: BasicAllocationColumns,
    followUp: BasicFollowUpColumns,
    clients: BasicClientColumns,
    celulasPerformance: BasicCelulasPerformance,
    savingFunds: BasicSavingFundsColumns,
  },
  [Role.Admin]: {
    pages: [
      Pages.Home,
      Pages.NewClient,
      Pages.Clients,
      Pages.NewCredit,
      Pages.FollowUpMore,
      Pages.Allocation,
      Pages.Commissions,
      Pages.PaidCommissions,
      Pages.SavingsFunds,
      Pages.Trash,
      Pages.AuthorizationCard,
      Pages.ClientDetail,
      Pages.CreditDetail,
      Pages.CommissionsControl,
      Pages.Celulas,
      Pages.Operators,
    ],
    commissions: AdminCommissionsColumns,
    allocation: BasicAllocationColumns,
    followUp: BasicFollowUpColumns,
    clients: BasicClientColumns,
    celulasPerformance: BasicCelulasPerformance,
    savingFunds: BasicSavingFundsColumns,
  },
}

export default RolesConfig
